// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-customer-convenience-js": () => import("./../../../src/pages/customer-convenience.js" /* webpackChunkName: "component---src-pages-customer-convenience-js" */),
  "component---src-pages-financial-relief-js": () => import("./../../../src/pages/financial-relief.js" /* webpackChunkName: "component---src-pages-financial-relief-js" */),
  "component---src-pages-health-well-being-js": () => import("./../../../src/pages/health-well-being.js" /* webpackChunkName: "component---src-pages-health-well-being-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

