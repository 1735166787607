import * as React from "react"
// import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main>
      <p>404 page</p>
    </main>
  )
}

export default NotFoundPage
